.prompt_input_bg {
  background: linear-gradient(
    83.98deg,
    rgba(29, 29, 29, 0.83) 2.84%,
    rgba(29, 29, 29, 0.81) 97.18%
  );
}

.prompt_button_1 {
  border: 0.5px solid var(--Colour-Gray-Gray---07, #333333);
  background: linear-gradient(
    106.19deg,
    rgba(19, 19, 19, 0.63) 2.23%,
    rgba(19, 19, 19, 0.65) 100.87%
  );
}

input:focus {
  outline: none;
}
