@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap');

:root {
  --gradient: linear-gradient(
    to left,
    rgb(67, 91, 99) 0%,

    rgba(9, 103, 131, 0.6) 70%,
    rgba(130, 225, 255, 0.2) 80%,
    rgba(130, 225, 255, 0.1) 100%
  );
}

@layer base {
  img {
    display: initial;
  }
}

.App {
  width: 100%;
}

/* .sidebarWidth1 {
	width: 5%;
}
.sidebarWidth2 {
	width: 20%;
} */

/* ::-webkit-scrollbar {
  width: 0.5em;
  background: #060608;
}

::-webkit-scrollbar-track {
  background: #060608;
  margin-block: 1em;
}
::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 100vw;
}

@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: #6f6f6f #333;
    scrollbar-width: 30px;
  }
} */

* {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

/* For Webkit-based browsers (Chrome, Safari, Edge) */
*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: 'Instrument Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

.nameSpan {
  border-radius: var(--Spacing-Spacing-8, 32px);
  border: 0.5px solid rgba(130, 225, 255, 0);
  background: linear-gradient(60deg, #0c0c0c 2.59%, #0b0b0b 99.44%);

  /* Button shadows */
  box-shadow:
    0px 5px 3px 1px rgba(255, 255, 255, 0.15) inset,
    0px -10px 8px 0px rgba(0, 0, 0, 0.2) inset;
}

.authBgImg {
  background-image: url(/src/assets/authBg.svg);
}

.authBorder {
  position: relative;
  background: linear-gradient(
    353.59deg,
    #1d1d1d 2.81%,
    rgba(36, 36, 36, 0.95) 98.62%
  );
  border-radius: 20px;
  z-index: 1; /* Ensure content stays above pseudo-element */
}

.authBorder::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  padding: 1px; /* Adjust this to match your border width */
  background: linear-gradient(
    221.67deg,
    rgba(45, 205, 255, 0) 9.88%,
    rgba(167, 231, 252, 0.48) 34.01%,
    rgba(0, 194, 255, 0.2064) 55.74%,
    rgba(167, 231, 252, 0.2112) 98.77%
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1;
}

.currentPlanHolder {
  position: relative;
  background: linear-gradient(
    83.98deg,
    rgba(21, 21, 21, 0.89) 2.84%,
    rgba(23, 23, 23, 0.84) 53.87%,
    rgba(21, 21, 21, 0.64) 97.18%
  );
  border-radius: 10px;
  z-index: 1;
}

.currentPlanHolder::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  padding: 1px;
  background: linear-gradient(
    269.91deg,
    rgba(183, 235, 252, 0.57) 0%,
    rgba(86, 208, 247, 0.28) 41.42%,
    rgba(222, 222, 222, 0.03) 99.21%
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1;
}

.otpBg {
  position: relative;
  background: var(--Frame-Colour, #121212);
  border-radius: 6px; /* Adjust the border radius as needed */
  z-index: 1; /* Ensure content is above the pseudo-element */
}

.otpBg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px; /* Match the parent element's border radius */
  padding: 0.5px; /* This should match the border width */
  background: linear-gradient(
    89.91deg,
    rgba(90, 90, 90, 0.28) -2.35%,
    rgba(119, 119, 119, 0.09) 30.31%,
    rgba(97, 217, 255, 0.38) 99.92%
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1; /* Place behind the `.otpBg` content */
}

.btn-primary {
  border: 0.5px solid #181818;
  background: #333;

  box-shadow:
    0px -10px 8px 0px rgba(0, 0, 0, 0.2) inset,
    0px 5px 3px 1px rgba(255, 255, 255, 0.15) inset;
}
.btn-primary2 {
  background: var(--Colour-Gray-Gray---06, #737373);
  border: 1px solid var(--Colour-system-Hover-button, #333333);
  box-shadow: 0px -10px 8px 0px #00000033 inset;

  box-shadow: 0px 5px 3px 1px #ffffff26 inset;
}
.btn-secondary {
  border: 0.5px solid #181818;
  background: #f0eeee;

  /* Button shadows */
  box-shadow:
    0px -10px 8px 0px rgba(0, 0, 0, 0.2) inset,
    0px 5px 3px 1px rgba(255, 255, 255, 0.15) inset;
}
.btn-blue {
  border: 1px solid #016c8e;
  background: #016c8e;
  box-shadow:
    0px 5px 3px 1px rgba(255, 255, 255, 0.15) inset,
    0px -10px 8px 0px rgba(0, 0, 0, 0.2) inset;
}
.btn-stroke {
  border: 1px solid #a6a6a6;
  background: transparent;
}
.btn-red-stroke {
  border: 1px solid #ff9595;
  background: transparent;
}
.btn-secondary-red {
  border: 0.5px solid #ff2a2a;
  background: #f0eeee;

  /* Button shadows */
  box-shadow:
    0px -10px 8px 0px rgba(0, 0, 0, 0.2) inset,
    0px 5px 3px 1px rgba(255, 255, 255, 0.15) inset;
}

input[type='file'] {
  display: none;
}
.labelBg {
  background: linear-gradient(
    60deg,
    rgba(46, 46, 46, 0.5) 2.59%,
    rgba(30, 30, 30, 0.5) 99.44%
  );
}
.bg1 {
  background: linear-gradient(
    83.98deg,
    rgba(31, 31, 31, 0.48) 2.84%,
    rgba(20, 20, 20, 0.62) 97.18%
  );
}
.bg2 {
  background: linear-gradient(60deg, #1e1e1e 2.59%, #0f0f0f 99.44%);
}
.bg3 {
  background: linear-gradient(60deg, #070707 2.59%, #050505 99.44%);
}
.bg4 {
  background: linear-gradient(60deg, #0e0e0e 2.59%, #111 99.44%);
}
.bg5 {
  background: linear-gradient(60deg, #0c0c0c 2.59%, #101010 99.44%);
}
.bg6 {
  background: linear-gradient(
    84deg,
    rgba(23, 23, 23, 0.99) 2.84%,
    rgba(21, 21, 21, 0.9) 97.18%
  );
}

.bg7 {
  background: linear-gradient(
    106.19deg,
    rgba(27, 27, 27, 0.72) 2.23%,
    rgba(35, 35, 35, 0.65) 100.87%
  );
}

.bg8 {
  background: linear-gradient(60.05deg, #2d2d2d 2.58%, #252525 99.44%);
  border: 1px solid;

  border-image-source: linear-gradient(
    90deg,
    rgba(46, 46, 46, 0.7) 0%,
    rgba(46, 46, 46, 0.77) 100%
  );

  box-shadow: 0px 1px 2px 0px #1018280d;
}
.ticketBodyBg {
  background: linear-gradient(60.05deg, #1d1d1d 2.59%, #1b1a1a 99.44%);
  border: 0.5px solid var(--Colour-Gray-Gray---07, #333333);
}
.innerFrameBg {
  background: linear-gradient(60.05deg, #0c0c0c 2.59%, #0c0c0c 99.44%);
  border: 1px solid;

  border-image-source: linear-gradient(
    110.48deg,
    rgba(33, 33, 33, 0.32) -1.16%,
    rgba(33, 33, 33, 0.32) 111.25%
  );
}
.googleInputBg {
  background: linear-gradient(
    60deg,
    rgba(13, 13, 13, 0.5) 2.59%,
    rgba(14, 14, 14, 0.5) 99.44%
  );
}
.pricingCardBg {
  background: linear-gradient(180deg, #181818 0%, #171717 42.57%, #181818 100%);
  border: 0.4px solid #5f5f5f;
  box-shadow: 0px 0px 32px 8px #00000047;
}

.liteCardBg {
  border: 1px solid var(--Colour-Teal-Teal---04, #56acc7);
  background: linear-gradient(180deg, #242424 0%, #212121 42.57%, #242424 100%);
  box-shadow: 0px 0px 37.57px 9.39px #00000047;
}
.projectBg {
  background: linear-gradient(
    84deg,
    rgba(31, 31, 31, 0.33) 2.84%,
    rgba(20, 20, 20, 0.52) 97.18%
  );
}
.uploadHolderBg {
  background: linear-gradient(
    83.98deg,
    rgba(50, 50, 50, 0.33) 2.84%,
    rgba(42, 42, 42, 0.52) 97.18%
  );
}
.uploadHolderBg2 {
  background: linear-gradient(
    83.98deg,
    rgba(11, 11, 11, 0.33) 2.84%,
    rgba(20, 20, 20, 0.52) 97.18%
  );
}
.projectsBg {
  background: linear-gradient(
    84deg,
    rgba(31, 31, 31, 0.33) 2.84%,
    rgba(20, 20, 20, 0.52) 97.18%
  );
}
.pageBtnBg {
  border: 0.5px solid rgba(113, 215, 248, 0.1);

  background: #016c8e;
  box-shadow:
    0px 5px 3px 1px rgba(66, 66, 66, 0.15) inset,
    0px -10px 8px 0px rgba(19, 19, 19, 0.35) inset;
}
.paginationBtn {
  border: 0.5px solid rgba(113, 215, 248, 0.1);

  background: linear-gradient(
    84deg,
    rgba(32, 32, 32, 0.33) 21.7%,
    rgba(27, 27, 27, 0.52) 97.18%
  );

  box-shadow:
    0px 5px 3px 1px rgba(66, 66, 66, 0.15) inset,
    0px -10px 8px 0px rgba(19, 19, 19, 0.35) inset;
}
.pollingBtn {
  border: 0.217px solid rgba(130, 225, 255, 0);

  background: var(--Colour-Orange-Orange---07, #cc7628);
  box-shadow:
    0px 2.17px 1.302px 0.434px rgba(255, 255, 255, 0.15) inset,
    0px -4.34px 3.472px 0px rgba(0, 0, 0, 0.2) inset;
}
.notificationBg {
  background: linear-gradient(
    84deg,
    rgba(34, 34, 34, 0.06) 2.84%,
    rgba(34, 34, 34, 0.52) 97.18%
  );
  backdrop-filter: blur(20px);
}

.gradientBg5 {
  background: linear-gradient(
    84deg,
    rgba(32, 32, 32, 0.11) 2.84%,
    rgba(12, 12, 12, 0.84) 53.87%,
    rgba(21, 21, 21, 0.37) 97.18%
  );

  border: 0.5px solid rgba(183, 235, 252, 0.57);

  backdrop-filter: blur(4px);
}

.integrationCardBg {
  border: 0.5px solid var(--Colour-Gray-Gray---07, #333);
  background: linear-gradient(180deg, #1c1c1c 0%, #101010 42.57%, #111 100%);
  backdrop-filter: blur(4px);
}

.fullBorder {
  --border-width: 1px;
  --border-radius: 12px;
  background: var(--gradient);
  border: 0;
  position: relative;
  border-radius: var(--border-radius);
  box-shadow: 2px 2px 4px 0px rgba(255, 255, 255, 0.06) inset;
  z-index: 1;
}
.fullBorder::before {
  content: '';
  display: block;
  height: calc(100% - calc(var(--border-width) * 2));
  width: calc(100% - calc(var(--border-width) * 2));
  background: linear-gradient(180deg, #111 0%, #040404 100%);
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  border-radius: calc(var(--border-radius) - var(--border-width));
  z-index: -1;
}
.fullBorder2 {
  --border-width: 0.5px;
  --border-radius: 100px;
  background: var(--gradient);
  border: 0;
  position: relative;
  border-radius: var(--border-radius);
  box-shadow: 2px 2px 4px 0px rgba(255, 255, 255, 0.06) inset;
  z-index: 1;
}
.fullBorder2::before {
  content: '';
  display: block;
  height: calc(100% - calc(var(--border-width) * 2));
  width: calc(100% - calc(var(--border-width) * 2));
  background: linear-gradient(180deg, #111 0%, #040404 100%);
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  border-radius: calc(var(--border-radius) - var(--border-width));
  z-index: -1;
}

.fullBorder3 {
  --border-width: 1px;
  --border-radius: 8px;
  background: var(--gradient);
  border: 0;
  position: relative;
  border-radius: var(--border-radius);
  box-shadow: 2px 2px 4px 0px rgba(255, 255, 255, 0.06) inset;
  z-index: 1;
}

.fullBorder3::before {
  content: '';
  display: block;
  height: calc(100% - calc(var(--border-width) * 2));
  width: calc(100% - calc(var(--border-width) * 2));
  /* background: linear-gradient(180deg, #111 0%, #040404 100%); */
  background: #000;
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  border-radius: calc(var(--border-radius) - var(--border-width));
  z-index: -1;
}
.fullBorder4 {
  border: 0.5px solid rgba(4, 92, 120, 0.67);

  background: linear-gradient(
    106deg,
    rgba(19, 19, 19, 0.63) 2.23%,
    rgba(19, 19, 19, 0.65) 100.87%
  );
}

.fullBorder5 {
  --border-width: 1px;
  --border-radius: 8px;
  background: var(--gradient);
  border: 0;
  position: relative;
  border-radius: var(--border-radius);
  box-shadow: 2px 2px 4px 0px rgba(255, 255, 255, 0.06) inset;
  z-index: 1;
}

.fullBorder5::before {
  content: '';
  display: block;
  height: calc(100% - calc(var(--border-width) * 2));
  width: calc(100% - calc(var(--border-width) * 2));
  /* background: linear-gradient(180deg, #111 0%, #040404 100%); */
  background: linear-gradient(
    84deg,
    rgba(34, 34, 34, 0.06) 2.84%,
    rgba(34, 34, 34, 0.52) 97.18%
  );
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  border-radius: calc(var(--border-radius) - var(--border-width));
  z-index: -1;
}

.fullBorder5 {
  border: 1px solid rgba(4, 92, 120, 0.67);

  background: linear-gradient(
    84deg,
    rgba(34, 34, 34, 0.06) 2.84%,
    rgba(34, 34, 34, 0.52) 97.18%
  );
}
.fullBorder6 {
  border: 1px solid rgba(4, 92, 120, 0.67);

  background: linear-gradient(
    84deg,
    rgba(103, 102, 102, 0.06) 2.84%,
    rgba(90, 90, 90, 0.52) 97.18%
  );
  box-shadow: 0px 0px 32px 8px rgba(0, 0, 0, 0.28);
}

.inputBg {
  background: linear-gradient(
    84deg,
    rgba(0, 0, 0, 0.33) 2.84%,
    rgba(33, 33, 33, 0.52) 97.18%
  );
  border: 1px solid rgba(33, 33, 33, 0.64);
}
.inputBg2 {
  background: linear-gradient(
    84deg,
    rgba(27, 27, 27, 0.33) 2.84%,
    rgba(22, 22, 22, 0.52) 97.18%
  );
  border: 0.5px solid rgba(33, 33, 33, 0.64);
}
.gradientBg4 {
  background: linear-gradient(
    106deg,
    #0b0b0b 2.23%,
    rgba(13, 13, 13, 0.96) 100.87%
  );
}
.sidebarBg {
  background: linear-gradient(
    84deg,
    rgba(19, 19, 19, 0.06) 2.84%,
    rgba(21, 21, 21, 0.52) 97.18%
  );
}
.bgModal {
  background: linear-gradient(83.98deg, #151414 2.84%, #161616 97.18%);
  border: 1px solid;

  border-image-source: linear-gradient(
    110.48deg,
    rgba(33, 33, 33, 0.32) -1.16%,
    rgba(33, 33, 33, 0.32) 111.25%
  );

  box-shadow: 0px 0px 32px 8px #00000047;
}
.upload_doc_inputBg {
  background: linear-gradient(
    83.98deg,
    rgba(50, 50, 50, 0.33) 2.84%,
    rgba(42, 42, 42, 0.52) 97.18%
  );
  border: 0.5px solid;

  border-image-source: linear-gradient(
    110.48deg,
    rgba(84, 84, 84, 0.32) -1.16%,
    rgba(95, 93, 93, 0.32) 111.25%
  );
  /* border-image-slice: 1; */
}
.modalBg {
  background: linear-gradient(
      60deg,
      rgba(13, 13, 13, 0.5) 2.59%,
      rgba(14, 14, 14, 0.5) 99.44%
    ),
    linear-gradient(60deg, #0e0e0e 2.58%, #0c0c0c 99.44%);
  border: 1px solid rgba(33, 33, 33, 0.64);
  box-shadow: 0px 0px 32px 8px rgba(0, 0, 0, 0.28);
}

.modalBg2 {
  background: linear-gradient(83.98deg, #151414 2.84%, #161616 97.18%);
  border: 0.5px solid var(--Colour-Gray-Gray---07, #333333);
}
.modalBg3 {
  background: linear-gradient(
    83.98deg,
    rgba(28, 28, 28, 0.99) 2.84%,
    rgba(25, 25, 25, 0.9) 97.18%
  );
  border: 1px solid;

  border-image-source: linear-gradient(
    110.48deg,
    rgba(40, 40, 40, 0.64) -1.16%,
    rgba(44, 44, 44, 0.64) 111.25%
  );

  box-shadow: 0px 0px 32px 8px #00000047;
}

.cardBg {
  background: linear-gradient(
    84deg,
    rgba(34, 34, 34, 0.06) 2.84%,
    rgba(34, 34, 34, 0.52) 97.18%
  );
}

.optionBg {
  background: linear-gradient(
    83.98deg,
    rgba(69, 69, 69, 0.33) 2.84%,
    rgba(38, 38, 38, 0.52) 97.18%
  );
  border: 0.5px solid var(--Colour-Gray-Gray---07, #333333);
  backdrop-filter: blur(8px);
}

/* -----Modals---------- */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal-left {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}
.modal-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.868);
}
.notification-modal-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.068);
}

.modal-container {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  /* overflow-y: auto; */
}
.notification-modal-container {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  overflow: scroll;
}
/* .notification-modal-container::before {
	content: "";
	width: 50px;
	height: 50px;
	position: absolute;
	background: #fff;
	transform: rotate(45deg);
	top: -40px;
} */
.modal-container-status {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  top: 10vh;
}

.project-modal {
  position: absolute;
  bottom: 5px;
  right: 10px;
  height: 80vh;
}

@property --angle {
  syntax: '<angle>';
  inherits: true;
  initial-value: 0turn;
}
@keyframes spin {
  to {
    --angle: 1turn;
  }
}

.glowBorder {
  --border-radius: 50%;
  --border-width: 1px;
  position: relative;
  background: transparent;
  border-radius: var(--border-radius);
  animation: spin 3s linear infinite;
}
.glowBorder::before {
  content: '';
  background-image: conic-gradient(
    from var(--angle),
    rgb(0, 0, 0) 40%,
    #c0dcfe 88%,
    #c0dcfe 92%,
    rgb(0, 0, 0) 100%
  );
  position: absolute;
  inset: calc(var(--border-width) * -1);
  z-index: -1;
  border-radius: inherit;
}
.glowBorder2 {
  --border-radius: 50%;
  --border-width: 1px;
  position: relative;
  background: transparent;
  border-radius: var(--border-radius);
  animation: spin 1s linear infinite;
}
.glowBorder2::before {
  content: '';
  background-image: conic-gradient(
    from var(--angle),
    rgb(0, 0, 0) 40%,
    #c7b7a8 88%,
    #c0bdbb 92%,
    rgb(0, 0, 0) 100%
  );
  position: absolute;
  inset: calc(var(--border-width) * -1);
  z-index: -1;
  border-radius: inherit;
}

.upload-area {
  border: 1px dashed #333;
}
.dragging {
  border: 1px dashed #aad5e3;
}
.loading-area {
  border: 1px dashed #333;
}
.uploaded-area {
  border: 1px dashed #333;
}

section .row {
  margin-bottom: 10px;
  list-style: none;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section .details span {
  font-size: 12px;
}
.loading-area .row .content {
  width: 70%;
  margin-left: 15px;
}
.loading-area .details {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  justify-content: space-between;
}
.loading-area .content .loading-bar {
  height: 6px;
  width: 100%;
  margin-bottom: 4px;
  background: #333;
  border-radius: 30px;
}
.content .loading-bar .loading {
  height: 100%;
  width: 10%;
  background: rgba(255, 114, 114, 0.67);
  border-radius: inherit;
}
.upload-area.onprogress {
  max-height: 150px;
}
.upload-area::-webkit-scrollbar {
  width: 0px;
}
.upload-area .row .content {
  display: flex;
  align-items: center;
}
.upload-area .row .details {
  display: flex;
  margin-left: 15px;
  flex-direction: column;
}
.upload-area .row .details .size {
  font-size: 11px;
}

.checkContainer label span {
  transition: 0.5s;
  user-select: none;
  overflow: hidden;
}
.checkContainer label span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
}

.checkContainer label input[type='checkbox']:checked ~ span {
  background: #181818;
  color: #b9b9b9;
  border: 1px solid #b9b9b9;
}

@keyframes opacityAnimation {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.1;
  }
}

.opacity-animation {
  animation: opacityAnimation 3s ease-in-out infinite; /* Duration and timing function */
}

.toast-container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: none;
}

.toast {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  /* border-radius: 0.25rem; */
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.custom-dash {
  /* position: relative; */
  width: 100%;
}

.custom-dash::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: repeating-linear-gradient(
    to right,
    #33333340,
    #33333340 10px,
    transparent 10px,
    transparent 20px
  );
}

/* ----------- insights ----------- */

.line_chart_frame_bg {
  background: linear-gradient(
    223deg,
    rgba(87, 87, 87, 0.06) 55.29%,
    rgba(9, 196, 255, 0.02) 88.28%
  );
}

.insights_bg_1 {
  background: linear-gradient(
    83.98deg,
    rgba(31, 31, 31, 0.48) 2.84%,
    rgba(20, 20, 20, 0.62) 97.18%
  );
}

.chart-container-bg {
  background: linear-gradient(
    83.98deg,
    rgba(30, 30, 30, 0.33) 2.84%,
    rgba(26, 26, 26, 0.52) 97.18%
  );
  /* backdrop-filter: blur(8px); */
}

.projectDropdownBg {
  background: linear-gradient(
    83.98deg,
    rgba(40, 40, 40, 0.83) 2.84%,
    rgba(40, 40, 40, 0.81) 97.18%
  );
  border: 0.5px solid var(--Colour-Gray-Gray---07, #333333);
}

/* -------------- table ---------------- */

.table-head-bg {
  background: linear-gradient(
    83.98deg,
    rgba(30, 30, 30, 0.33) 2.84%,
    rgba(26, 26, 26, 0.52) 97.18%
  );
}

.table-row {
  margin-bottom: 0.5rem; /* Adjust as needed */
}

/* -------------------- refresh button ------------- */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: spin 1s linear infinite;
}

.custom-tooltip {
  transform: translateY(-10px); /* Moves tooltip 10px upward */
  left: 50%;
  transform: translateX(-0%);
}
