.prompt_input_bg {
  background: linear-gradient(83.98deg, #202020 2.84%, #2e2e2e 97.18%);
  border: 1px solid;

  border-image-source: linear-gradient(
    90deg,
    #262727 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  box-shadow: 0px -10px 8px 0px #00000033 inset;

  box-shadow: 0px 5px 3px 1px #48484826 inset;
}

.prompt_button_1 {
  border: 0.5px solid var(--Colour-Gray-Gray---07, #333333);
  background: linear-gradient(
    106.19deg,
    rgba(19, 19, 19, 0.63) 2.23%,
    rgba(19, 19, 19, 0.65) 100.87%
  );
}
